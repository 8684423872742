function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import LRUCache from "/web_modules/lru-cache.js";
export let ScanDirection;

(function (ScanDirection) {
  ScanDirection[ScanDirection["Forwards"] = 1] = "Forwards";
  ScanDirection[ScanDirection["Backwards"] = -1] = "Backwards";
})(ScanDirection || (ScanDirection = {}));

export default class SegmentCollection {
  constructor(baseUrl, {
    segments: segmentIds
  }, chunkSize = 20) {
    this.baseUrl = baseUrl;
    this.chunkSize = chunkSize;

    _defineProperty(this, "cache", void 0);

    _defineProperty(this, "segmentIds", void 0);

    this.cache = new LRUCache({
      max: chunkSize * 2
    });
    this.segmentIds = segmentIds;
  }

  findRange(i, direction) {
    const moveIndex = i + direction * this.chunkSize;

    if (direction === ScanDirection.Forwards) {
      return [i, Math.min(moveIndex, this.segmentIds.length)];
    } else {
      return [Math.max(0, moveIndex), i + 1];
    }
  }

  get(i, direction) {
    if (i < 0 || i >= this.segmentIds.length) {
      throw new RangeError();
    }

    const segmentId = this.segmentIds[i]; // Reset age so the current segment doesn't get invalidated if it's cached

    this.cache.get(segmentId);
    const fetchIds = this.segmentIds.slice(...this.findRange(i, direction)).filter(id => !this.cache.has(id));

    if (fetchIds.length) {
      const fetchParams = new URLSearchParams(fetchIds.map(id => ["find", id]));
      const segmentReq = fetch(`${this.baseUrl.href}?${fetchParams}`);
      const segmentReqBody = segmentReq.then(x => x.json());

      for (const id of fetchIds) {
        this.cache.set(id, segmentReqBody.then(segments => segments.find(seg => seg._id === id)));
      }
    }

    return this.cache.get(segmentId);
  }

  get length() {
    return this.segmentIds.length;
  }

}
